exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-index-tsx": () => import("./../../../src/pages/aboutus/index.tsx" /* webpackChunkName: "component---src-pages-aboutus-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-environmentalpolicy-tsx": () => import("./../../../src/pages/environmentalpolicy.tsx" /* webpackChunkName: "component---src-pages-environmentalpolicy-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-joinus-careers-tsx": () => import("./../../../src/pages/joinus/careers.tsx" /* webpackChunkName: "component---src-pages-joinus-careers-tsx" */),
  "component---src-pages-joinus-freelance-tsx": () => import("./../../../src/pages/joinus/freelance.tsx" /* webpackChunkName: "component---src-pages-joinus-freelance-tsx" */),
  "component---src-pages-joinus-index-tsx": () => import("./../../../src/pages/joinus/index.tsx" /* webpackChunkName: "component---src-pages-joinus-index-tsx" */),
  "component---src-pages-ourteam-index-tsx": () => import("./../../../src/pages/ourteam/index.tsx" /* webpackChunkName: "component---src-pages-ourteam-index-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-requestaquote-tsx": () => import("./../../../src/pages/requestaquote.tsx" /* webpackChunkName: "component---src-pages-requestaquote-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-termsandconditions-tsx": () => import("./../../../src/pages/termsandconditions.tsx" /* webpackChunkName: "component---src-pages-termsandconditions-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-blog-details-tsx": () => import("./../../../src/templates/blogDetails.tsx" /* webpackChunkName: "component---src-templates-blog-details-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

